<template>
  <v-chip
    :small="small"
    color="white--text orange darken-4"
    label
  >
    <v-icon
      small
      left
    >
      mdi-alert-box-outline
    </v-icon>
    PLUS EN STOCK
  </v-chip>
</template>

<script>
export default {
  name: 'NoStockChip',
  props: {
    small: { type: Boolean },
  },
}
</script>

<style scoped>

</style>

<template>
  <v-chip
    class="mx-2 my-2"
    :class="getColor(status)"
  >
    {{ $t('status.automation.type.' + type.toLowerCase()) }} :
    {{ $t('status.automation.state.' + status.toLowerCase() + '.label') }}
  </v-chip>
</template>

<script>
export default {
  name: 'AutomationChip',
  
  props: {
    type: {
      type: String,
      required: true,
    },
    status: {
      type: String,
      required: true,
    },
  },
  methods: {
    getColor(status) {
      
      switch (status.toLowerCase()) {
      case 'requested':
        return 'grey lighten-1'
      case 'pending':
        return 'teal lighten-4'
      case 'done':
        return 'white--text green darken-1'
      }
      
      return ''
    },
  },
}
</script>

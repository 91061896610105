<template>
  <v-select
    v-model="providerId"
    hide-details
    clearable
    outlined
    prepend-inner-icon="mdi-database-marker-outline"
    dense
    item-text="name"
    item-value="id"
    :items="values"
    :label="inputLabel('Provider', required)"
    :error-messages="error"
    @change="$emit('update:error', null)"
  />
</template>

<script>
import inputMixin from '@/mixins/input'

export default {
  name: 'ProviderSelector',
  mixins: [ inputMixin ],
  props: {
    value: {
      type: String,
      default: null,
    },
    required: Boolean,
    error: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      values: [],
    }
  },
  computed: {
    providerId: {
      get () { return this.value  },
      set (value) { this.$emit('input', value) },
    },
  },
  async created() {
    const responseProvider = await this.$axios.get('external_catalog/providers')
    this.values = responseProvider.data['hydra:member']
      .map(
        provider => {
          return {
            id: provider.id,
            name: provider.name,
          }
        }
      )
  },
}
</script>

<template>
  <v-btn
    :href="link"
    color="blue lighten-1"
    icon
    target="_blank"
    :small="small"
  >
    <v-icon
      :small="small"
    >
      mdi-open-in-new
    </v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'LinkProduct',
  props: {
    link: {
      type: String,
      required: true,
    },
    small: { type: Boolean },
  },
}
</script>
<style scoped>

</style>
